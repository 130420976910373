$('[data-prevent]').keyup(function () {

    let $el = $(this);

    if (!$el.hasClass('is-active')) {
        $el.addClass('is-active');
        $el.after('<br><p class="alert alert-danger">' + $el.data('prevent') + '</p>');
    }

});
