//Ancre scroll
$('a[href^="#"].ancre').click(function(){
    var the_id = $(this).attr("href");
    if (the_id === '#') {
        return;
    }
    $('html, body').animate({
        scrollTop:$(the_id).offset().top
    }, 'slow');
    return false;
});

//

$('#formcommentaires').on('submit', function() {
    $('#formcommentaires .spinner-border').removeClass('hidden');
});

if($("#commentaires .alert")[0]) {
    $('html, body').animate({
        scrollTop:$('#ancrecoms').offset().top+80
    }, 'slow');
}
