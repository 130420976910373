//Imports fichiers js
require('./bootstrap');
require('./lazyload');
require('./friconix');
require('./HeaderSticky');
require('./FooterSticky');
require('./breadcrumb');
//require('./UploadFile');
require('./BackToTop');
require('./AncreScroll');
require('./MenuItemActive');
require('./TinyMCE');
require('./ChampsSensibles');
require('./Datepicker');
require('./FormAjax');
