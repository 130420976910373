$(document).on('submit', '.form-ajax form', function (e) {

    e.preventDefault();
    var form_parent = $(this).parent();
    var formData = new FormData(this);
    form = form_parent.find('form');

    form.find('button[type=submit]')
        .attr('disabled', 'disabled')
        .find('.loader')
        .removeClass('hidden');

    $.ajax({
            url: form.attr('action'),
            type: 'POST',
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        })
        .done(function () {
            form.trigger('reset');
            form_parent.find('.alert')
                .removeClass('hidden')
                .attr('class', 'alert alert-success')
                .text(form_parent.attr('data-success'));
        })
        .fail(function (data) {
            var response = JSON.parse(data.responseText);
            // console.log(response);
            var errorString = '<p><b>Erreurs :</b></p><ul>';
            $.each(response.errors, function (key, value) {
                let label = key;
                const errorsMap = {
                    'type' : 'Type de prestation',
                    'sous_type' : 'Sous-type de prestation',
                    'ompimarque' : 'Marque déposée en France',
                    'nbreclasse' : 'Nombre de classes',
                    'precisezpays' : 'Pays',
                    'preciseztype' : 'Pays',
                    'precisezcg' : 'Pays',
                    'precisezrgpdautre' : 'Pays',
                    'csformesoc' : 'Forme de la société',
                    'rjobligachat' : 'Obligation d\'achat',
                    'autrerr' : 'Nom de la rédaction du règlement',
                    'autrepresta' : 'Autre',
                    'nom' : 'Nom de famille',
                    'prenom' : 'Prénom',
                    'telephone' : 'Téléphone',
                    'expbesoin' : 'Expression de besoin',
                    'postalcode' : 'Code postal',
                    'acceptcgu' : 'Politique de confidentialité',
                };

                if (errorsMap.hasOwnProperty(key)) {
                    label = errorsMap[key];
                }

                errorString += '<li>' + label + ' : ' + value + '</li>';
            });
            errorString += '</ul>';
            form_parent.find('.alert')
                .attr('class', 'alert alert-danger')
                .html(errorString);
        })
        .always(function () {
            form.find('button[type=submit]')
                .removeAttr('disabled')
                .find('.loader')
                .addClass('hidden');

            $([document.documentElement, document.body]).animate({
                scrollTop: form_parent.find('.alert').offset().top - 100
            }, 500);
        });
});
