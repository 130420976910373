tinyMCE.baseURL = "/vendor/tinymce";

tinymce.init({
    content_css: "/css/admin.css",
    //selector: 'textarea.tinymce',
    selector: 'textarea.tinymce',
    branding: false,
    menubar: false,
    entity_encoding : "raw",
    plugins: ['link', 'image', 'lists', 'paste', 'code'],
    toolbar: [
        'styleselect | bold italic underline | link image | alignleft aligncenter alignright | numlist bullist | paste pastetext | undo redo | divider | initAccordion accordion | code | initSelect',
    ],
    schema: "html5",
    extended_valid_elements:"@[itemscope|itemtype|itemid|itemprop|content|id|style|class],div,span,time[datetime],p,span,section,select[class|multiple],i[class],itemprop[name|mainEntity|acceptedAnswer|text],h3[itemprop]",
    image_advtab: true,
    images_upload_url: '/admin/images/',
    images_upload_handler: function(blobInfo, success, failure){
        var xhr, formData;

        xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('POST', '/admin/images/');
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));

        xhr.onload = function() {

            var json;
            json = JSON.parse(xhr.responseText);

            if (xhr.status != 200) {

                let errorMsg = xhr.status;

                if(typeof json.errors.file !== 'undefined'){

                    let errors = json.errors.file;
                    for(error in errors){
                        errorMsg += '<br>' + errors[error];
                    }

                }

                failure('HTTP Error: ' + errorMsg);
                return;
            }


            if (!json || typeof json.location != 'string') {
                failure('Invalid JSON: ' + xhr.responseText);
                return;
            }

            success(json.location);
        };

        formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());

        xhr.send(formData);
    },

    setup: function (editor) {
        editor.on('change', function () {
            editor.save();
        });

        //Divider
        editor.ui.registry.addButton('divider', {
            icon: 'horizontal-rule',
            tooltip: "Insérer un divider",
            onAction: function (_) {
                editor.insertContent('<div class="divider is-darkgrey w-100 mb-3"></div><br/>');
            }
        });

        //Initialiser un accordion
        editor.ui.registry.addButton('initAccordion', {
            icon: 'table-caption',
            tooltip: "Initialiser un accordion",
            onAction: function (_) {
                editor.insertContent('<div id="accordion" class="panel-group mt-2 mb-5 accordionFAQ" itemscope itemtype="https://schema.org/FAQPage"></div>');
            }
        });

        //Accordion
        editor.ui.registry.addButton('accordion', {
            text: '<image src="https://static.thenounproject.com/png/1814255-200.png" style="height: 24px;width: 24px;padding: 3px 0px 0px 0px;"/ >',
            tooltip: "Insérer un accordion",
            onAction: function (_) {
                editor.insertContent('<div class="panel" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question"><div class="panel-heading"><div class="panel-title"><h3 itemprop="name"><a href="#MarqueFrance" class="accordion-toggle" data-toggle="collapse" data-parent="#accordion">Comment déposer une marque en France ?</a></h3></div></div><div id="MarqueFrance" class="panel-collapse collapse show" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer"><div class="panel-body" itemprop="text"><p>Le dépôt de marque</p></div></div></div><br/>');
            }
        });

        //Select
        editor.ui.registry.addButton('initSelect', {
            icon: 'checklist',
            tooltip: "Initialiser un select",
            onAction: function (_) {
                editor.insertContent('<select class="is-select2 mySelect for" multiple="multiple"></select>');
            }
        });

    }

});
