//Active l'item du menu selon l'url
function getCookie(name){
    if(document.cookie.length == 0)
    return null;

    var regSepCookie = new RegExp('(; )', 'g');
    var cookies = document.cookie.split(regSepCookie);

    for(var i = 0; i < cookies.length; i++){
        var regInfo = new RegExp('=', 'g');
        var infos = cookies[i].split(regInfo);
        if(infos[0] == name){
            return unescape(infos[1]);
        }
    }
    return null;
}

var lastSelectedElement = null

$('.nav li a').on('click', function() {
    $('.nav li.level1').removeClass('clicked');
    $('.nav li.level1').removeClass('active');
      $(this).parentsUntil('containermenu', 'li.level1').addClass('clicked');
      var clicked = $('.nav li.level1.clicked').map(function() {
            return '#' + this.id;
      }).get().join(',');
      document.cookie = 'MyCookie='+clicked;
});

var url = window.location.pathname;
//Home
if (url == '/') {
    $('.nav li.level1').removeClass('clicked');
    $('.nav li.level1').removeClass('active');
    $('.nav li.level1.home').addClass('clicked');
    $('.nav li.level1.home').addClass('active');
}
//Cgv
else if (window.location.href.indexOf("prestation-redaction-conditions-generales") > -1) {
    $('.nav li.level1').removeClass('clicked');
    $('.nav li.level1').removeClass('active');
    $('.nav li.level1.cgv').addClass('clicked');
    $('.nav li.level1.cgv').addClass('active');
}
//Contrats
else if (window.location.href.indexOf("prestation-redaction-contrat") > -1) {
    $('.nav li.level1').removeClass('clicked');
    $('.nav li.level1').removeClass('active');
    $('.nav li.level1.contrats').addClass('clicked');
    $('.nav li.level1.contrats').addClass('active');
}
//Marques
else if (window.location.href.indexOf("prestation-depot-marque") > -1) {
    $('.nav li.level1').removeClass('clicked');
    $('.nav li.level1').removeClass('active');
    $('.nav li.level1.marques').addClass('clicked');
    $('.nav li.level1.marques').addClass('active');
}
//Rgpd
else if (window.location.href.indexOf("prestation-conformite-rgpd") > -1) {
    $('.nav li.level1').removeClass('clicked');
    $('.nav li.level1').removeClass('active');
    $('.nav li.level1.rgpd').addClass('clicked');
    $('.nav li.level1.rgpd').addClass('active');
}
//status
else if (window.location.href.indexOf("prestation-status") > -1) {
    $('.nav li.level1').removeClass('clicked');
    $('.nav li.level1').removeClass('active');
    $('.nav li.level1.status').addClass('clicked');
    $('.nav li.level1.status').addClass('active');
}
//Articles
else if (window.location.href.indexOf("articles") > -1) {
    $('.nav li.level1').removeClass('clicked');
    $('.nav li.level1').removeClass('active');
    $('.nav li.level1.articles').addClass('clicked');
    $('.nav li.level1.articles').addClass('active');
}
//Actualités
else if (window.location.href.indexOf("actualites-juridiques") > -1) {
    $('.nav li.level1').removeClass('clicked');
    $('.nav li.level1').removeClass('active');
    $('.nav li.level1.actus').addClass('clicked');
    $('.nav li.level1.actus').addClass('active');
}
//Questions Reponses
else if (window.location.href.indexOf("questions-reponses") > -1) {
    $('.nav li.level1').removeClass('clicked');
    $('.nav li.level1').removeClass('active');
    $('.nav li.level1.qr').addClass('clicked');
    $('.nav li.level1.qr').addClass('active');
}
//A propos
else if (window.location.href.indexOf("a-propos") > -1) {
    $('.nav li.level1').removeClass('clicked');
    $('.nav li.level1').removeClass('active');
    $('.nav li.level1.apropos').addClass('clicked');
    $('.nav li.level1.apropos').addClass('active');
}
//Devis
else if (window.location.href.indexOf("admin") > -1) {
    $('.nav li.level1').removeClass('clicked');
    $('.nav li.level1').removeClass('active');
    $('.nav li.level1.admin').addClass('clicked');
    $('.nav li.level1.admin').addClass('active');
}
else if (window.location.href.indexOf("devis") > -1) {
    $('.nav li.level1').removeClass('clicked');
    $('.nav li.level1').removeClass('active');
    $('.nav li.level1.devis').addClass('clicked');
    $('.nav li.level1.devis').addClass('active');
}
else {
    /*var MyCookie = getCookie("MyCookie");
    $(MyCookie).addClass('clicked');
    $(MyCookie).addClass('active');*/
    $('.nav li.level1').removeClass('active');
}
