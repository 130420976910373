try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
    require('tinymce');
    require('tinymce/plugins/code');
    require('tinymce/plugins/link');
    require('tinymce/plugins/image');
    require('bootstrap-select');
    require('bootstrap-select/dist/css/bootstrap-select.css');
    require('bootstrap-datepicker');
    require('bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.css');

} catch (e) {}